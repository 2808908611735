const colors = {
  white: '#FFFFFF',
  black: '#000000',
  blue: '#002ED6',
  grey: '#888B99',
  lightGrey: '#E0E0E0',
  mediumGrey: '#E7E7E7'
};

export default colors;
