const breakpoints = {
  xxl: "1400px",
  xl: "1200px",
  lg: "992px",
  md: "768px",
  sm: "576px",
  xs: "575px"
};

export default breakpoints;
