import React, { Component } from "react"
import styled from "styled-components"
import logoWhite from "../../resources/img/logo/logo-white.svg"
import logoBlue from "../../resources/img/logo/logo-blue.svg"
import { Link } from "gatsby"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import burger from "../../resources/img/ui/burger.svg"
import burgerBlue from "../../resources/img/ui/burgerBlue.svg"
import MobileNav from "./MobileNav"
import { isBrowser, setBodyOverflow } from "../../services/browser"

const HeaderBlock = styled.div`
  position: relative;
  width: 100%;
  // max-width: 1314px;
  margin: auto;
  padding: 35px 32px 0 32px;
  height: 81px;
  display: block;
  z-index: 50;
  
  @media (min-width: ${breakpoints.md}) {
    padding: 46px 50px 0 50px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HomeLink = styled(Link)`
  
`

const Logo = styled.img`
  display: ${props => props.show ? 'block' : 'none'};
`

const MobileNavContainer = styled.div`
  display: flex;
  
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Nav = styled.div`
  display: none;
  
  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`

const NavItem = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`

const NavLink = styled(Link)`
  color: ${props => props.hero ? colors.white : colors.blue};
  text-transform: uppercase;
  ${fonts.avantGardeMedium};
  font-size: 12px;
  letter-spacing: 2px;
  padding-bottom: 8px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  
  &:hover,
  &.active {
    border-color: ${props => props.twosided ? colors.blue : props.hero ? colors.white : colors.blue};
  }
  
  @media(min-width: ${breakpoints.lg}){
    color: ${props => props.twosided ? colors.blue : props.hero ? colors.white : colors.blue};
  }
`

const BurgerMenu = styled.div`
`

const Burger = styled.img`
  border: 0;
  outline: none;
  box-shadow: none;
  width: 36px;
  height: 15px;
  cursor: pointer;
  display: ${props => props.show ? 'block' : 'none'};
`

class Header extends Component {
  state = {
    isOpen: false,
  }

  componentDidMount = () => {
    if (isBrowser()) {
      setBodyOverflow(false)
    }
  }

  toggleNav = () => {
    const isOpen = !this.state.isOpen

    this.setState({
      isOpen: isOpen,
    })

    setBodyOverflow(isOpen)
  }

  closeIfActive(e) {
    if (e.currentTarget.className.includes("active")) {
      this.toggleNav()
    }
  }

  render() {
    const { isOpen } = this.state
    const { hero, twosided } = this.props
    const { toggleNav } = this

    return (
      <HeaderBlock>
        <HeaderContainer>
          <HomeLink to={"/"} aria-label={'yelo partners'}>
            <Logo src={logoWhite} show={hero} alt={'yelo partners logo'}/>
            <Logo src={logoBlue} show={!hero} alt={'yelo partners logo'}/>
          </HomeLink>
          <Nav>
            <NavItem>
              <NavLink to={"/what-we-do"} activeClassName={"active"} hero={hero} twosided={twosided}>
                What We Do
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={"/brands"} activeClassName={"active"} hero={hero} twosided={twosided}>
                Brands
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={"/athletes"} activeClassName={"active"} hero={hero} twosided={twosided}>
                Athletes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={"/about"} activeClassName={"active"} hero={hero} twosided={twosided}>
                About Us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={"/contact"} activeClassName={"active"} hero={hero} twosided={twosided}>
                Contact
              </NavLink>
            </NavItem>
          </Nav>
          <MobileNavContainer>
            <BurgerMenu>
              <Burger src={burger} alt={"Mobile Nav"} onClick={toggleNav} show={hero}/>
              <Burger src={burgerBlue} alt={"Mobile Nav"} onClick={toggleNav} show={!hero}/>
            </BurgerMenu>
            <MobileNav toggleNav={toggleNav} isOpen={isOpen} closeIfActive={this.closeIfActive.bind(this)}/>
          </MobileNavContainer>
        </HeaderContainer>
      </HeaderBlock>
    )
  }
}

export default Header
