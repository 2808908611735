import React from 'react';
import styled from "styled-components";
import logoWhite from "../../resources/img/logo/logo-white.svg";
import arrows from "../../resources/img/ui/nav-arrows.png";
import close from "../../resources/img/ui/close.svg";
import colors from "../../styles/colors"
import { Link } from "gatsby"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"

const MobileNavContainer = styled.div`
  display: ${props => props.isOpen ? "block" : "none"};
  padding: 35px 32px;
  background: ${colors.blue}  no-repeat;
  background-position: bottom right;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: 262.83px 296px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HomeLink = styled(Link)`
`;

const Logo = styled.img`
`;

const CloseButton = styled.div`
`;

const Close = styled.img`
`;

const Nav = styled.div`
  margin-top: 30%;
`;

const NavItem = styled.div`
  margin-bottom: 2px;
`;

const NavLink = styled(Link)`
  color: ${colors.white};
  text-transform: uppercase;
  ${fonts.avantGardeBold};
  font-size: 36px;
  line-height: 59px;
  text-decoration: none;
  
  &:hover,
  &.active {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.white};
    -webkit-text-stroke-width: 1px;
  }
`;

const Arrow = styled.div`
  background: url(${arrows}) no-repeat;
  height: 30vh;
  width: 100%;
  background-position: center right;
  background-size: auto 100%;
  background-origin: content-box;
  position: absolute;
  bottom: 0;
  left: 0;
` 

const MobileNav = ({isOpen, toggleNav, closeIfActive}) => (
  <MobileNavContainer isOpen={isOpen === true}>
    <Header>
      <HomeLink to={'/'}>
        <Logo src={logoWhite} />
      </HomeLink>
      <CloseButton onClick={toggleNav}>
        <Close src={close} alt={'Close'}/>
      </CloseButton>
    </Header>
    <Nav>
      <NavItem>
        <NavLink to={'/what-we-do'} activeClassName={"active"} onClick={closeIfActive}>
          What We Do
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={'/brands'} activeClassName={"active"} onClick={closeIfActive}>
          Brands
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={'/athletes'} activeClassName={"active"} onClick={closeIfActive}>
          Athletes
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={'/about'} activeClassName={"active"} onClick={closeIfActive}>
          About Us
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={'/contact'} activeClassName={"active"} onClick={closeIfActive}>
          Contact
        </NavLink>
      </NavItem>
    </Nav>
    <Arrow />
  </MobileNavContainer>
);

export default MobileNav;
