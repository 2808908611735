import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GlobalStyle from '../../styles/global'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {BaseCSS} from 'styled-bootstrap-grid';
import {GridThemeProvider} from 'styled-bootstrap-grid';
import {ThemeProvider} from 'styled-components';
import {gsap} from 'gsap';
import {CSSPlugin} from 'gsap/CSSPlugin';
import Header from "./Header";
import Footer from "./Footer";
import breakpoints from "../../styles/breakpoints";

gsap.registerPlugin(CSSPlugin);

const PageContainer = styled.div`
    width: 100%;
    /* margin-top: 55px; */
    flex-grow: 1;
    @media(min-width: ${breakpoints.md}) {
      /* margin-top: 100px; */
    }
`;

const GlobalPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
  },
  row: {
    padding: 0
  },
  col: {
    padding: 0
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const styledTheme = {};

const Layout = ({children, hero, twosided, isFooter=true}) => (
  <React.Fragment>
    <ThemeProvider theme={styledTheme}>
      <GridThemeProvider gridTheme={gridTheme}>
        <GlobalStyle/>
        <BaseCSS/>
        <GlobalPageContainer>
          <Header hero={hero} twosided={twosided}/>
          <PageContainer>
            {children}
          </PageContainer>
          {isFooter && <Footer/>}
        </GlobalPageContainer>
      </GridThemeProvider>
    </ThemeProvider>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
