import {createGlobalStyle} from 'styled-components';
import reset from 'styled-reset';
import avantGardeBoldWoff from '../resources/fonts/ITCAvantGardeStd-Bold.woff';
import avantGardeBoldWoff2 from '../resources/fonts/ITCAvantGardeStd-Bold.woff2';
import avantGardeMdWoff from '../resources/fonts/ITCAvantGardeStd-Md.woff';
import avantGardeMdWoff2 from '../resources/fonts/ITCAvantGardeStd-Md.woff2';
import avantGardeXLtWoff from '../resources/fonts/ITCAvantGardeStd-XLt.woff';
import avantGardeXLtWoff2 from '../resources/fonts/ITCAvantGardeStd-XLt.woff2';
import fonts from './fonts';

const fontFace = (fontFamily, woff, woff2) => {
    return `
      @font-face {
          font-family: '${fontFamily}';
          src: url(${woff2}) format('woff2'),
          url(${woff}) format('woff');
          font-weight: normal;
          font-style: normal;
      }
    `;
};

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${fontFace('Avant Garde Bold', avantGardeBoldWoff, avantGardeBoldWoff2)}
    ${fontFace('Avant Garde Medium', avantGardeMdWoff, avantGardeMdWoff2)}
    ${fontFace('Avant Garde Light', avantGardeXLtWoff, avantGardeXLtWoff2)}
    
    body {
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      ${fonts.avantGardrLight};
    }
`;

export default GlobalStyle;
