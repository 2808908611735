import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "styled-bootstrap-grid"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Instagram from "../../resources/img/instagram.svg"
import LinkedIn from "../../resources/img/linkedin.svg"
import BannerArrow from "../../resources/img/ui/contact-arrow.png"
import ExternalLink from "../Core/ExternalLink"

const ColStyled = styled(Col)`
  display: grid;
  padding: 20px 20px;
  justify-content: center;
  align-items: center;
`

const PartnerBanner = styled(Row)`
  height: 224px;
  overflow: hidden;
  padding-left: 12px;
  min-height: 220px;

  @media (min-width: ${breakpoints.md}) {
    height: 630px;
  }
`

const ContactBox = styled.div`
  padding: 40px 33px;
  margin-bottom: -260px;
  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    padding: 0;
    top: 40%;
    left: 5%;
    margin: 0;
  }
`

const ContactTextOutlined = styled.div`
  ${fonts.avantGardeBold};
  font-size: 23px;
  padding: 4px 0;
  line-height: 32px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: ${colors.blue};
  -webkit-text-stroke-width: 1px;
  @media (min-width: 390px) {
    font-size: 26.4px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 44px;
    line-height: 53px;
  }
`

const ContactText = styled(ExternalLink)`
  ${fonts.avantGardeBold};
  font-size: 23px;
  line-height: 32px;
  color: ${colors.blue};
  text-decoration: none;
  @media (min-width: 390px) {
    font-size: 26.4px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 44px;
    line-height: 53px;
  }
`

const StyledParagraph = styled.p`
  line-height: 24px;
  margin-bottom: 20px;
  font-size: 16px;
  min-width: 166px;
  max-width: 304px;
  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    top: 25%;
    left: 10%;
  }
`

const Social = styled.div`
  padding: 60px 0 40px;
  div {
    padding: 20px 20px 20px 0;
    cursor: pointer;
    display: inline-block;
  }
  @media (min-width: ${breakpoints.md}) {
    padding-top: 140px;
  }
`

const ContainerStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: auto;
`

const FooterContainer = styled.footer`
  width: 100%;
  height: 224px;
  background: url(${BannerArrow}) no-repeat;
  background-position: center right;
  background-size: auto 100%;
  @media (min-width: ${breakpoints.md}) {
    height: 100%;
  }
`

const StyledExternalLink = styled(ExternalLink)`
  padding: 20px 38px 20px 0;
  img {
    height: 16px;
  }
`

const Footer = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulGetInTouch {
          content
          title
          email
        }
      }
    `
  )
  if (!data) return null

  return (
    <FooterContainer>
      <ContainerStyled>
        <Container fluid>
          <PartnerBanner>
            <ColStyled xs={8} sm={8} md={6}>
              <StyledParagraph>{data.content}</StyledParagraph>
            </ColStyled>
          </PartnerBanner>
          <ContactBox>
            <ContactTextOutlined>{data.title}</ContactTextOutlined>
            <ContactText href={`mailto:${data.email}`}>
              {data.email}
            </ContactText>
            <Social>
              <StyledExternalLink
                href={`https://www.instagram.com/yelopartners/`}
              >
                <img src={Instagram} alt={"Instagram"} />
              </StyledExternalLink>
              <StyledExternalLink
                href={`https://www.linkedin.com/company/yelo-partners`}
              >
                <img src={LinkedIn} alt={"Instagram"} />
              </StyledExternalLink>
            </Social>
          </ContactBox>
        </Container>
      </ContainerStyled>
    </FooterContainer>
  )
}

export default Footer
