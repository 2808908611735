function setBodyOverflow(isOpen) {
  let overflow = 'auto';

  if (isOpen) {
    overflow = 'hidden';
  }

  let documentBody = document.getElementsByTagName('body')[0];

  let style = 'overflow: ' + overflow + ';';

  let ua = window.navigator.userAgent;
  let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

  if (isOpen && iOS) {
    style += 'height: 100%; position: fixed;';
  } else {
    style += 'height: auto; position: relative';
  }

  documentBody.style = style;
}

function isBrowser() {
  return typeof window !== "undefined";
}

export {
  isBrowser,
  setBodyOverflow
}
